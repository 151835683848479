<template>
	<v-container class="EDC-Container EDC-SchRow EDC-RowsSpacing">
		<v-row class="EDC-Row" align="start">
			<v-col class="EDC-Col EDC-UIPadding" cols="2">
	      <v-row class="EDC-Row EDC-RowsSpacing">
	        <v-col class="EDC-Col EDC-UIPadding" cols="12">
	          <calender format='dddd, MMMM Do YYYY' :input="scheduleObject.start_date" @update="setStartDate" style=" float:left" :label="beginDateLabel" :dense="true" key="startdate" :isMinDate="true"></calender>
	        </v-col>
	      </v-row>
	      <v-row class="EDC-Row EDC-RowsSpacing" v-if="showEndDate">
	        <v-col class="EDC-Col EDC-UIPadding" cols="12">
	          <calender format='dddd, MMMM Do YYYY' :input="scheduleObject.end_date" :label="endDateLabel" :isMinDate="true" @update="setEndDate" :dense="true" key="enddate" style="float:left"/>
	        </v-col>
	      </v-row>
	    </v-col>
	    <v-col class="EDC-Col EDC-UIPadding" cols="2">
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-col class="EDC-Col EDC-UIPadding" cols="12">
            <timer :inputtime="start_clock_model" @update="updateStartTime" :label="startTimeLabel" dense key="starttime"></timer>
          </v-col>
        </v-row>
        <v-row class="EDC-Row EDC-RowsSpacing" v-if="showEndTime">
          <v-col class="EDC-Col EDC-UIPadding" cols="12">
          <timer :inputtime="end_clock_model" key="endtime" :label="endTimeLabel" @update="updateEndTime"/>
          </v-col>
        </v-row>
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-col class="EDC-Col EDC-UIPadding" cols="12">
            <v-autocomplete hide-details v-model="scheduleObject.timezone" label="Time Zone" :items="timeZoneItems" style="float:left;width:100%" dense></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="EDC-Col" cols="1"></v-col>
      <v-col class="EDC-Col EDC-UIPadding" cols="3">
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-col class="EDC-Col EDC-UIPadding" cols="12" style="padding-top:4px !important;">
            <v-autocomplete hide-details dense @focus="manageRecurrenceList" v-model="recurrenceType" label="Recurrence" :items="recurrenceTypeList"></v-autocomplete>
          </v-col>
        </v-row>
        
        <v-row class="EDC-Col EDC-RowsSpacing" v-if="giveMonthlyOpt">
          <v-col class="EDC-Col EDC-UIPadding">
            <v-row class="EDC-Row">
              <v-col class="EDC-Col" align="left">
                Select Custom Months
              </v-col>
            </v-row>
            <v-row class="EDC-Row EDC-RowsSpacing" v-for="(rowitem,rowidx) in [0,4,8]" :key="rowidx+'r'">
              <v-col class="EDC-Col" cols="3" v-for="(colitem,colidx) in [0,1,2,3]" :key="colidx+'c'">
                <v-chip dense small :class="{'EDC-selected-chip':dayOfMonthArray[rowitem+colitem].value,'EDC-deselected-chip':!dayOfMonthArray[rowitem+colitem].value}" @click="toggleCustomMonthSelection(dayOfMonthArray[rowitem+colitem],rowitem+colitem)">{{dayOfMonthArray[rowitem+colitem].text}}</v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-col class="EDC-Col EDC-UIPadding" cols="12" v-if="recurrenceType==='custom'">
            <v-row class="EDC-Row">
              <v-col class="EDC-Col" cols="4" align="left">
                <v-radio-group v-model="recurrenceCustomType" hide-details dense>
                  <v-radio class="radioClass" :color="colorCode" label="Weekly" value="custom_weekly"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="EDC-Col" cols="8" v-if="recurrenceCustomType==='custom_weekly'">
                <v-row class="EDC-Row EDC-RowsSpacing" v-for="(rowitem,rowidx) in [0,4]" :key="rowidx+'r'">
                  <v-col class="EDC-Col" cols="3" v-for="(colitem,colidx) in [0,1,2,3]" :key="colidx+'c'">
                    <v-chip dense small v-if="rowitem+colitem < 7" :class="{'EDC-selected-chip':dayOfWeeks[rowitem+colitem].value,'EDC-deselected-chip':!dayOfWeeks[rowitem+colitem].value}" @click="toggleCustomDaySelection(dayOfWeeks[rowitem+colitem],rowitem+colitem)">{{dayOfWeeks[rowitem+colitem].text}}</v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="EDC-Row">
              <v-col class="EDC-Col" cols="4" align="left">
                <v-radio-group v-model="recurrenceCustomType" hide-details dense>
                  <v-radio class="radioClass"  :color="colorCode" label="Monthly" value="custom_monthly"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="EDC-Col" cols="8" v-if="recurrenceCustomType==='custom_monthly'">
                <v-row class="EDC-Row EDC-RowsSpacing" v-for="(rowitem,rowidx) in [0,4,8]" :key="rowidx+'r'">
                  <v-col class="EDC-Col" cols="3" v-for="(colitem,colidx) in [0,1,2,3]" :key="colidx+'c'">
                    <v-chip dense small :class="{'EDC-selected-chip':dayOfMonthArray[rowitem+colitem].value,'EDC-deselected-chip':!dayOfMonthArray[rowitem+colitem].value}" @click="toggleCustomMonthSelection(dayOfMonthArray[rowitem+colitem],rowitem+colitem)">{{dayOfMonthArray[rowitem+colitem].text}}</v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col class="EDC-Col EDC-UIPadding" cols="1" v-if="showEndOn" align="left">
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-col class="EDC-Col EDC-UIPadding" cols="12">
            Ends:
          </v-col>
        </v-row>
      </v-col> -->
      <v-col class="EDC-Col EDC-UIPadding" cols="1"></v-col>
      <v-col class="EDC-Col EDC-UIPadding" cols="3" v-if="showEndOn" align="left">
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-col class="EDC-Col EDC-UIPadding" cols="2">
            Ends:
          </v-col>
          <v-col class="EDC-Col EDC-UIPadding" cols="10">
            <v-radio-group @change="changeRangeOfReccurance" v-model="recurrenceEndOn" hide-details dense>
            <v-radio class="radioClass" :color="colorCode" label="Never" value="indefinitely"></v-radio>
          </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-col class="EDC-Col EDC-UIPadding" cols="2"></v-col>
          <v-col class="EDC-Col" cols="1">
            <v-radio-group v-model="recurrenceEndOn" @change="changeRangeOfReccurance" hide-details dense>
              <v-radio class="radioClass" :color="colorCode" label="" value="until_date"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="EDC-Col EDC-CalenderMargin" cols="9">
            <calender :input="scheduleObject.recurring.range_of_recurrence.until_date" @update="setUntillDate" :dense="true" :isMinDate="true" :disabled="recurrenceEndOn!='until_date'"></calender>
          </v-col>
        </v-row>
        <v-row class="EDC-Row EDC-RowsSpacing">
          <v-row class="EDC-Row">
            <v-col class="EDC-Col EDC-UIPadding" cols="2"></v-col>
            <v-col class="EDC-Col" cols="1">
              <v-radio-group @change="changeRangeOfReccurance" v-model="recurrenceEndOn" hide-details dense>
                <v-radio class="radioClass" :color="colorCode" label="" value="afterNinteration"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="EDC-Col EDC-CalenderMargin" cols="9">
              <v-text-field v-model="scheduleObject.recurring.range_of_recurrence.end_after_times" label="End After Iterations" hide-details dense style="float:left;width:100%" :disabled="recurrenceEndOn!='afterNinteration'"></v-text-field>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
		</v-row>
    <v-row class="EDC-Row EDC-RowsSpacing" justify="end" style="margin-right:5px !important">
      <vc-button itemText="Add" @click.native="saveSchedule" :disabled="disabledAdd"></vc-button>
      <vc-button itemText="Cancel" @click.native="cancelSchedule"></vc-button>
    </v-row>
	</v-container>
</template>
<script>

	import Calender from './../calender'
	import Timer from "./../timecontrol"

	import {
  	EDC_SCHEDULE_OBJECT,
  	DAY_OF_WEEKS,
  	DAY_OF_MONTH_ARRAY,
    WEEK_DAYS
	} from '../../data/metaData.js'
	import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
	var moment = require("moment-timezone");

	export default {
		name:'edcSchedule',
		components:{
			'timer':Timer,
			Calender
		},
		props:{
			edcScheduleObject:{
				type:Object
			},
			beginDateLabel:{
				type:String,
				default:"DD-MM-YYYY"
			},
			endDateLabel:{
				type:String,
				default:"DD-MM-YYYY"
			},
			startTimeLabel:{
				type:String,
				default:"Start Time (24HH)"
			},
			endTimeLabel:{
				type:String,
				default:"End Time (24HH)"
			},
      showEndDate:{
        type:Boolean,
        default:false
      },
      showEndTime:{
        type:Boolean,
        default:false
      },
      isOneTimeOnly:{
        type:Boolean,
        default:false
      },
      scheduleFor:{
        type:String,
        default:'job_scheduler'
      }
		},
		watch:{
			'edcScheduleObject':{
				handler(newValue){
          if(!_.isEmpty(newValue)){
					 this.scheduleObject = _.cloneDeep(newValue)
          this.manageJobScheduler()
          }
				}
			},
			'beginDateLabel':{
				handler(newValue){

				}
			},
			'endDateLabel':{
				handler(newValue){

				}
			},
      'showEndDate':{
        handler(newValue){

        }
      },
      'showEndTime':{
        handler(newValue){

        }
      },
      'isOneTimeOnly':{
        handler(newValue){
          
        }
      },
      'scheduleFor':{
        handler(newValue){

        }
      }
		},
		computed: {
    	showEndOn(){
      	return this.recurrenceType!='one_time'
    	},
      disabledAdd(){
        return !this.scheduleObject.start_date || !this.start_clock_model || (this.showEndDate && !this.scheduleObject.end_date) || (this.showEndTime && !this.end_clock_model)
      },
      isSchedulerForJob(){
        return this.scheduleFor === 'job_scheduler'
      },
      isSchedulerForCalendar(){
        return this.scheduleFor === 'job_calendar'
      },
      giveMonthlyOpt(){
        return this.recurrenceType === 'customonthlylastdayinweek'
      }


  	},
		data(){
			return{
				scheduleObject:_.cloneDeep(EDC_SCHEDULE_OBJECT),
				recurrenceType:'one_time',
				start_clock_model:'',
				end_clock_model:'',
				timeZoneItems: moment.tz.names(),
				recurrenceTypeList:[],
				dayOfWeeks:_.cloneDeep(DAY_OF_WEEKS),
        dayOfMonthArray:_.cloneDeep(DAY_OF_MONTH_ARRAY),
        recurrenceCustomType:'custom_weekly',
        recurrenceEndOn:'indefinitely',
        outlineColor:BTN_COLOR,
      	colorCode:COLOR_CODE,
        schedule_type: "one_time", // this variable used to identify recurring or one time job.Same mistake done for radio button as explained for 'which_monthly_type' variable

			}
		},
    mounted(){
      this.scheduleObject.timezone = this.$session.get('time_zone_preference');
    },
		methods:{
      manageJobScheduler(){
        let _this = this
        this.scheduleObject.start_date = this.scheduleObject.start_from
        this.manageRecurrenceList()
        this.Time24to12(this.scheduleObject.start_time,'Start')
        if(this.scheduleObject.end_time && this.isSchedulerForCalendar){
          this.Time24to12(this.scheduleObject.end_time,'End')

        }
        this.setRecurranceForOldDesign()
        if(this.recurrenceType === 'custom'){
          _this.manageCustomSchedule()
        }
        if(this.giveMonthlyOpt){
          this.manageMonthlyOpt()
        }
        if(this.showEndOn){
          if(this.scheduleObject.recurring.range_of_recurrence.until_date)
            this.recurrenceEndOn = 'until_date'
          else if(this.scheduleObject.recurring.range_of_recurrence.end_after_times)
            this.recurrenceEndOn = 'afterNinteration'
        }
        
      },
      manageCustomSchedule(){
        let _this = this
        if(this.scheduleObject.recurring.recurrence_pattern.recurrence_pattern_type === 'weekly'){
            this.recurrenceCustomType = 'custom_weekly'
            this.scheduleObject.recurring.recurrence_pattern.week_array.forEach((obj)=>{
              _this.dayOfWeeks.forEach((day)=>{
                if(day.text === obj)
                  day.value = true
              })
            })  
          }
        else{
          this.recurrenceCustomType = 'custom_monthly'
          this.scheduleObject.recurring.recurrence_pattern.month_array.forEach((obj)=>{
            _this.dayOfMonthArray.forEach((month)=>{
              if(month.text === obj)
                month.value = true
            })
          })
        }
      },
      manageMonthlyOpt(){
        var _this = this
        this.scheduleObject.recurring.recurrence_pattern.month_array.forEach((obj)=>{
          _this.dayOfMonthArray.forEach((month)=>{
            if(month.text === obj)
              month.value = true
          })
        }) 
      },
      setRecurranceForOldDesign(){
        this.recurrenceType = this.scheduleObject.recurrenceType
        if(!this.recurrenceType){
          // It means its an old schedule.We need to convert that into the new design.
          if(this.scheduleObject.recurring.recurrence_pattern.recurrence_pattern_type === 'one_time')
              this.recurrenceType = "one_time"
          else if(this.scheduleObject.recurring.recurrence_pattern.recurrence_pattern_type === 'weekly' || this.scheduleObject.recurring.recurrence_pattern.recurrence_pattern_type === 'monthly')
              this.recurrenceType = "custom"
          else
            this.recurrenceType = "one_time"
        }
      },
			setStartDate(dateParam){
        let _this = this;
        _this.scheduleObject.start_date = dateParam;
        this.recurrenceType = 'one_time'
        this.manageRecurrenceList()
      },
      setEndDate(dateParam){
        let _this = this;
        _this.scheduleObject.end_date = dateParam;
        this.recurrenceType = 'one_time'
        this.manageRecurrenceList()
      },
      setUntillDate(dateParam) {
        let _this = this;
        _this.scheduleObject.recurring.range_of_recurrence.until_date = dateParam;
        _this.scheduleObject.recurring.range_of_recurrence.end_on = false;
      },
      updateStartTime(changedtime){
        this.start_clock_model = changedtime
        this.manageRecurrenceList()
      },
      updateEndTime(changedtime){
        this.end_clock_model = changedtime
        this.manageRecurrenceList()
      },
      manageRecurrenceList(){
        let _this = this
        // in case of edit,when time get updated, this get reset and it cause an issue
        if(_this.scheduleObject.recurrenceType != _this.recurrenceType){
          _this.recurrenceCustomType = "custom_weekly"
          _this.recurrenceType = 'one_time'
          // reset all selection
        }

        _this.dayOfMonthArray = _.cloneDeep(DAY_OF_MONTH_ARRAY)
        _this.dayOfWeeks = _.cloneDeep(DAY_OF_WEEKS)
        

        if(!this.showEndDate)
          _this.scheduleObject.end_date = _this.scheduleObject.start_date

        if(!_this.scheduleObject.start_date || !_this.scheduleObject.end_date || !_this.start_clock_model || (_this.showEndTime && !_this.end_clock_model)){
          _this.recurrenceTypeList = []
          return
        }
        let recurrenceTypeList = [{"value":"one_time","text":"Once","recurrence_pattern_type":"one_time"}]
        if(this.isOneTimeOnly){
          this.recurrenceTypeList = recurrenceTypeList
          return recurrenceTypeList
        }
        let startDate = moment(_this.scheduleObject.start_date)
        let endDate = moment(_this.scheduleObject.end_date)
        let dayDiff = endDate.diff(startDate,'days')


        if(dayDiff<6){
          let dayName = startDate.format('dddd')
          let dateValue = startDate.date()
          let weekNumber = Math.ceil(dateValue/7)
          if(_this.scheduleObject.start_date === _this.scheduleObject.end_date || _this.allowWeeklyOptionForTwoDiffDay(dayDiff)){
            recurrenceTypeList.push({"value":"everyday","text":"Everyday","recurrence_pattern_type":"weekly","moreInfo":{"week_array":_.map(DAY_OF_WEEKS,"text")}})
            if(dayName!='Saturday' && dayName!='Sunday')
              recurrenceTypeList.push({"value":"weekday","text":"Every Weekday(Monday to Friday)","recurrence_pattern_type":"weekly","moreInfo":{"week_array":_.map(WEEK_DAYS,"text")}})
          }

          recurrenceTypeList.push({"value":"everyweek","text":"Every "+dayName,"recurrence_pattern_type":"weekly","moreInfo":{
                "week_array":[_.find(DAY_OF_WEEKS,['fullname',dayName]).text]}
              })

          // if(this.isSchedulerForCalendar)
          recurrenceTypeList.push({"value":"alternateweek","text":"Every Alternate "+dayName,"recurrence_pattern_type":"weekly","moreInfo":{
            "week_array":[_.find(DAY_OF_WEEKS,['fullname',dayName]).text]}
          })

          let dateValueInString = dateValue.toString().padStart(2,"0")

          recurrenceTypeList.push({"value":"monthlyfirstday","text":"Monthly on "+weekNumber+_this.getDateInWord(weekNumber) + dayName,"moreInfo":{"which_day_of_week":+weekNumber+_this.getDateInWord(weekNumber).trim(),"selected_week_day":dayName.substring(0,3),"month_array":_.map(DAY_OF_MONTH_ARRAY,'text'),'is_day_of_month':true},"recurrence_pattern_type":"monthly"})

          recurrenceTypeList.push({"value":"monthlydate","text":"Every "+dateValue+_this.getDateInWord(dateValue)+" day of month","moreInfo":{"date_of_month":dateValueInString,"month_array":_.map(DAY_OF_MONTH_ARRAY,'text')},"recurrence_pattern_type":"monthly"})

          // here check is date belong to last week of the month, to identify it, 
          // we gone do it like dateValue + 7 > total days of month 
          if(dateValue+7 > startDate.daysInMonth()){
            recurrenceTypeList.push({"value":"monthlylastdayinweek", "text":"Last "+dayName + " Of Every Month", "recurrence_pattern_type":"monthly","moreInfo":{"which_day_of_week":"last","selected_week_day":dayName.substring(0,3),"month_array":_.map(DAY_OF_MONTH_ARRAY,'text'),'is_day_of_month':true}})

            recurrenceTypeList.push({"value":"customonthlylastdayinweek", "text":"Last "+dayName + " Of Selected Months", "recurrence_pattern_type":"monthly","moreInfo":{"which_day_of_week":"last","selected_week_day":dayName.substring(0,3), 'is_day_of_month':true}})
          
          }

          if(_this.scheduleObject.start_date === _this.scheduleObject.end_date && startDate.daysInMonth() === dateValue){
            recurrenceTypeList.push({"value":"monthlylastdate","text":"Every last day of month","moreInfo":{"date_of_month":"last","month_array":_.map(DAY_OF_MONTH_ARRAY,'text'), "last_day_of_month":true},"recurrence_pattern_type":"monthly"})
          }

          
          recurrenceTypeList.push({"value":"annually","text":"Annually on "+dateValue+_this.getDateInWord(dateValue).trim()+" "+startDate.format('MMMM'),"moreInfo":{"date_of_month":dateValueInString,"month":startDate.format('MMMM').substring(0,3),"month_array":[startDate.format('MMMM').substring(0,3)]},"recurrence_pattern_type":"annually"})

          recurrenceTypeList.push({"value":"custom","text":"Custom..."})

          _this.dayOfWeeks.forEach((obj)=>{
            if(obj.fullname!=dayName)
              obj.value = false
            else
              obj.isDefault=true
          })
          
          _this.dayOfMonthArray.forEach((obj)=>{
            if(obj.fullname!=startDate.format('MMMM'))
              obj.value = false
            else
              obj.isDefault=false
          })   
        }
        if(this.giveMonthlyOpt){
          this.manageMonthlyOpt()
        }
        // in case of edit,when time get updated, this get reset and it cause an issue. We also need to reassign value to the day and month
        if(_this.scheduleObject.recurrenceType === _this.recurrenceType && _this.recurrenceType ==='custom'){
          _this.manageCustomSchedule()
        }
        this.recurrenceTypeList = recurrenceTypeList
        return recurrenceTypeList
      },
      allowWeeklyOptionForTwoDiffDay(dayDiff){
        // Here we need to check is time difference is < 24 hours . If it is then we need to check add every day option to user.
        if(dayDiff!=1 || !this.start_clock_model || !this.end_clock_model)
          return false
        let startTimeNumber = parseInt(this.start_clock_model.replace(":",""))
        let endTimeNumber =  parseInt(this.end_clock_model.replace(":",""))
        return startTimeNumber > endTimeNumber

      },
      toggleCustomMonthSelection(monthItem,monthNumber){
        let _this = this
        // user cannot deselect start day 
        if(monthItem.isDefault)
          return
        else
          monthItem.value = !monthItem.value
      },
      toggleCustomDaySelection(dayItem,weekDayNumber){
        let _this = this
        // user cannot deselect start day 
        if(dayItem.isDefault)
          return
        let startDate = moment(_this.scheduleObject.start_date)
        let endDate = moment(_this.scheduleObject.end_date)
        let dayDiff = endDate.diff(startDate,'days')

        // user can deselect any day or select if dayDiff is 0
        if(dayItem.value || dayDiff===0 || this.allowWeeklyOptionForTwoDiffDay(dayDiff)){
          dayItem.value = !dayItem.value
          return
        }
        //user can select only those day which follow the day difference logic
        /* Now we have make available days based on the dayDiff.
           Logic is we will create new list where days will start from weekdayNumber.

           Then we will check is current day + dayDiff is not selected and will do reverse and check currentday-dayDiff is not selected
        */
        let startDayList = _.cloneDeep(_this.dayOfWeeks).splice(weekDayNumber,7)
        startDayList = startDayList.concat(_.cloneDeep(_this.dayOfWeeks).splice(0,weekDayNumber))

        let reverseDayList = _.reverse(_.cloneDeep(startDayList)).splice(0,6)
        reverseDayList.splice(0,0,dayItem)

        let isAnySelectedInNextDays = _.find(startDayList.splice(1,dayDiff),["value",true])
        let isSelectedInPreviousDays = _.find(reverseDayList.splice(1,dayDiff),["value",true])

        if(!isAnySelectedInNextDays && !isSelectedInPreviousDays)
          dayItem.value = true
      },
      getEndDay(startday,weekDayNumber){
        let _this = this
        let startDate = moment(_this.scheduleObject.start_date)
        let endDate = moment(_this.scheduleObject.end_date)
        let dayDiff = endDate.diff(startDate,'days')
        let startDayList = _.cloneDeep(_this.dayOfWeeks).splice(weekDayNumber,7)
        startDayList = startDayList.concat(_.cloneDeep(_this.dayOfWeeks).splice(0,weekDayNumber))
        return startDayList[dayDiff].text
      },
      saveSchedule(){

      // Weekday issue

        let _this =  this
        let copyOfScheduleObj = _.cloneDeep(this.scheduleObject)
        if(!this.start_clock_model || this.start_clock_model.trim().length != 5){
          alert('Wrong start time specified')
          return
        }
        copyOfScheduleObj.start_time = this.TimeInHHmm(this.start_clock_model)

        if(this.showEndTime){
          if(!this.end_clock_model || this.end_clock_model.trim().length != 5){
            alert('Wrong end time specified')
            return
          }
          copyOfScheduleObj.end_time = this.TimeInHHmm(this.end_clock_model)
        }
        let recurrenceDetails  = _.find(_this.recurrenceTypeList,["value",_this.recurrenceType])
        if(recurrenceDetails.value === 'custom'){
          copyOfScheduleObj.is_recurring = true
          if(copyOfScheduleObj.recurring.recurrence_pattern.is_day_of_month){
            // on altium we ran into the issue when user change last saturday of friday to custom monthly date.
            copyOfScheduleObj.recurring.recurrence_pattern.selected_week_day = ""
            copyOfScheduleObj.recurring.recurrence_pattern.which_day_of_week = ""
            copyOfScheduleObj.recurring.recurrence_pattern.is_day_of_month = false
          } 
          if(_this.recurrenceCustomType === 'custom_weekly'){
            _this.schedule_type = "weekly"
            let week_array = []
            let end_array = []
            for(let i=0;i<_this.dayOfWeeks.length;i++){
              if(!_this.dayOfWeeks[i].value)
                continue
              week_array.push(_this.dayOfWeeks[i].text)
              end_array.push(_this.getEndDay(_this.dayOfWeeks[i].text,i))
            }
            copyOfScheduleObj.recurring.recurrence_pattern.week_array=week_array
            copyOfScheduleObj.recurring.recurrence_pattern.end_day_of_week=end_array
          }
          else {
            let dateValueInString = moment(_this.scheduleObject.start_date).date().toString().padStart(2,"0")
            copyOfScheduleObj.recurring.recurrence_pattern.date_of_month=dateValueInString
            _this.schedule_type = 'monthly'
            copyOfScheduleObj.recurring.recurrence_pattern.month_array =_.map(_.filter(_this.dayOfMonthArray,(obj)=>{
              return obj.value
            }),"text")
          }
          copyOfScheduleObj.recurring.recurrence_pattern.recurrence_pattern_type = _this.schedule_type
        } else {
          _this.schedule_type = recurrenceDetails.recurrence_pattern_type
          copyOfScheduleObj.recurring.recurrence_pattern.recurrence_pattern_type = _this.schedule_type
          copyOfScheduleObj.recurring.recurrence_pattern['alternateweek'] = false
          if(recurrenceDetails.value === 'alternateweek'){
            copyOfScheduleObj.recurring.recurrence_pattern['alternateweek'] = true
          }
          if(recurrenceDetails.moreInfo){
            copyOfScheduleObj.is_recurring = true
            let moreInfoKeys = _.keys(recurrenceDetails.moreInfo)
            for(let i=0;i<moreInfoKeys.length;i++){
              copyOfScheduleObj.recurring.recurrence_pattern[moreInfoKeys[i]]=recurrenceDetails.moreInfo[moreInfoKeys[i]]
            }
            if(this.giveMonthlyOpt){
              let selectedMonths = _.map(_.filter(_this.dayOfMonthArray,(obj)=>{ 
                return obj.value
              }),"text")
              if(!selectedMonths.length){
                alert("Atleast select one month")
                return
              }
              copyOfScheduleObj.recurring.recurrence_pattern.month_array = selectedMonths
            }
          }
        }

        if(_this.recurrenceType === 'monthlyfirstday'){
          let endDate = moment(_this.scheduleObject.end_date)
          let dateValue = endDate.date()
          let weekNumber = Math.ceil(dateValue/7)+_this.getDateInWord(Math.ceil(dateValue/7)).trim()
          let endDayName = _.find(_this.dayOfWeeks, ['fullname',endDate.format('dddd')]).text
          copyOfScheduleObj.recurring.recurrence_pattern.end_selected_week_day = weekNumber
          copyOfScheduleObj.recurring.recurrence_pattern.end_which_day_of_week = endDayName
        }

        if(_this.schedule_type!='one_time'){
          // set until data
          if(_this.recurrenceEndOn === 'indefinitely')
            copyOfScheduleObj.recurring.range_of_recurrence.end_on=true 

        }
        else{
                  copyOfScheduleObj.is_recurring = false

        }
        if(_this.showEndDate)
            copyOfScheduleObj.endIndays = (moment(copyOfScheduleObj.end_date).date() - moment(copyOfScheduleObj.start_date).date()).toString().padStart(2,"0")
        copyOfScheduleObj.recurrenceType=_this.recurrenceType
        _this.$emit('saveSchedule',copyOfScheduleObj)

      },
      cancelSchedule(){
        this.$emit('cancelSchedule')
      },
      changeRangeOfReccurance(value){
        this.scheduleObject.recurring.range_of_recurrence.end_after_times=''
        this.scheduleObject.recurring.range_of_recurrence.until_date=null
      },
      Time24to12(time,for_what){
        var hours = time.HH
        var minute = time.mm
        if(for_what === 'Start')
          this.start_clock_model = hours+":"+minute
        else 
          this.end_clock_model = hours+":"+minute
      },
      TimeInHHmm(time){
        let split_by_colon = time.trim().split(':')
        let hours =split_by_colon[0] 
        let minute = split_by_colon[1] 
        return {'HH': hours,'mm': minute}
      },
      getDateInWord(number){
        let modVal = parseInt(number)%10
        if([0,4,5,6,7,8,9].indexOf(modVal)>-1)
          return 'th '
        if(modVal===1)
          return 'st '
        if(modVal===2)
          return 'nd '
        return 'rd '
      },
		}
	}
</script>
<style scoped>
  .radioClass >>> label{
    top: 3px !important;
  } 
  /*.radioClass >>> i{
    font-size: 30px
  }*/
  .customCheckbox >>> label{
    top: 5px !important;
  }
  /* .customCheckbox >>> i{
    top: 5px !important;
  } */
  .pointer{
    cursor: pointer;
  }
  .pointer >>> span{
    cursor: pointer;
  }
  .custWidth{
    width: 55px;
  }
  .customCheckbox >>> label{
    top: 0px !important;
  }
  .v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important; 
  }

  .v-divider--vertical {
    min-height:10%;
  }
  .theme--light.v-divider {
    border-color: rgba(0,0,0,.50);
  }

  .EDC-VCard {
    padding: 0px !important;
    margin: 12px !important;
    margin-bottom: 0px !important;
    height: 100% !important;
    width: 1230px !important;
  }

  .EDC-VCardTitle {
    background: #494949 !important;
    color: white !important;
    text-align: left !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin: 0px !important;
    height: 30px !important;
  }

  .EDC-SchRow{
    padding-left:10px !important;
    padding-right:10px !important;
  }

  .EDC-ColAlignPadding {
    padding-left:32px !important
  }

  .EDC-ColTimeZone {
    margin-top:4px !important;
  }

  .EDC-ColRadio {
    padding-top:8px !important;
    margin-left: -3px !important;
  }

  .EDC-ColRun{
    padding-top:15px !important;
  }

  .EDC-ColUntil{
    padding-top:10px !important;
  }

  .EDC-ColMainRadio{
    margin-top:6px !important;
  }

  .EDC-RadioMargin {
    margin-top: -8px !important;
    margin-left: -3px !important;
  }

  .EDC-CalenderMargin {
    margin-top: -8px !important;
    padding-left: 16px !important;
  }

  .EDC-TextfieldMargin {
    padding-left: 10px !important;
  }

  .EDC-UIPadding {
    padding-right:10px !important;
  }

  .EDC-ComboboxPadding {
    padding-top: 5px !important;
  }

  .EDC-Indefinitely {
    margin-top: 12px !important;
  }

  .EDC-Date {
    margin-top: 18px !important;
  }

</style>